<template>
  <div class="columns is-align-items-flex-end">
    <div class="column">
      <div class="field">
        <label>
          <span class="icon is-small">
            <i class="fal fa-filter" />
          </span>
          <span>
            Filters
          </span>
        </label>
      </div>
      <div
        class="buttons mb-0 pt-2"
        :class="{ 'is-centered': $mq === 'mobile' }"
      >
        <button
          v-for="(type, index) in filterTypes"
          :key="index"
          class="button is-rounded is-size-7 has-text-weight-normal"
          @click="open(type)"
          :class="[buttonClass(type), { 'is-fullwidth': $mq === 'mobile' }]"
          :disabled="isDisabled(type)"
        >
          {{ type.label }}
        </button>
        <button
          v-if="!moreFilters"
          class="button is-size-7 is-rounded has-text-primary has-text-weight-normal is-white is-text"
          @click="toggleMoreFilters"
        >
          Show more
        </button>
        <button
          v-if="moreFilters"
          class="button is-size-7 is-rounded has-text-primary has-text-weight-normal is-white is-text"
          @click="toggleMoreFilters"
        >
          Show less
        </button>
        <div
          class="button is-size-7 is-rounded has-text-danger has-text-weight-normal is-white is-text"
          @click="reset"
        >
          Reset
        </div>
      </div>
      <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
        mode="out-in"
      >
        <FilterDropdown
          v-if="active"
          :key="active.key"
          :filter="active"
          @close="active = null"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StockviewFilters',
  components: {
    FilterDropdown: () => import('./StockviewFilterDropdown')
  },
  data: () => ({
    active: null,
    moreFilters: false
  }),
  computed: {
    ...mapGetters('stockview/listings', [
      'listings',
      'filterOptions',
      'selectedFilters'
    ]),
    ...mapGetters('auth', ['permissions']),
    filterTypes() {
      if (this.moreFilters) return this.types
      else if (this.$mq !== 'mobile' && this.$mq !== 'desktop')
        return this.types.slice(0, 5)
      else return this.types.slice(0, 3)
    },
    types() {
      const filters = [
        {
          label: 'Make',
          icon: 'fa-list-ul',
          component: 'Checkbox',
          key: 'make'
        },
        {
          label: 'Model',
          icon: 'fa-list-ul',
          component: 'Checkbox',
          key: 'model'
        },
        {
          label: 'Trim',
          icon: 'fa-list-ul',
          component: 'Checkbox',
          key: 'trim'
        },
        {
          label: 'Mileage',
          icon: 'fa-tachometer',
          component: 'Slider',
          key: 'mileage'
        },
        {
          label: 'Days in stock',
          icon: 'fa-calendar-day',
          component: 'Slider',
          key: 'daysInStock'
        },
        {
          label: 'Listing price',
          icon: 'fa-tags',
          component: 'Slider',
          key: 'price'
        },
        {
          label: 'Registration year',
          icon: 'fa-calendar-alt',
          component: 'Registration',
          key: 'registrationYear'
        },
        {
          label: 'Price position',
          icon: 'fa-money-bill-wave',
          component: 'PricePosition',
          key: 'pricePosition'
        },
        {
          label: 'Vehicle status',
          icon: 'fa-car-building',
          component: 'Checkbox',
          key: 'vehicleStatus'
        }
      ]

      if (this.permissions.marketDemand) {
        filters.push({
          label: 'Market demand',
          icon: 'fa-heart',
          component: 'Slider',
          key: 'desirability'
        })
      }

      return filters
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['resetFilters', 'setFilterOptions']),
    async open(filter) {
      if (this.active !== filter) await this.setFilterOptions(filter.key)
      this.active === filter ? (this.active = null) : (this.active = filter)
    },
    buttonClass({ key }) {
      if (this.active?.key === key) return 'is-primary'
      else if (this.selectedFilters.includes(key))
        return 'is-primary is-outlined'
      return 'has-text-primary'
    },
    isDisabled({ key }) {
      const { listings, selectedFilters } = this
      return listings.items.length <= 1 && !selectedFilters.includes(key)
    },
    reset() {
      this.$mxp.track('stockview_stock_reset_all_filters')
      this.resetFilters()
    },
    toggleMoreFilters() {
      this.moreFilters = !this.moreFilters
    }
  }
}
</script>
